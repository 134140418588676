import React from "react";
import Img from "gatsby-image";
import { Variables, Color } from "./designToken";
import { renderTextNode } from "~/utils/renderTextNode";

type Props = {
  member: GatsbyTypes.ContentfulMember;
  memberIndex: number;
  onClick: (memberIndex: number) => void;
};

export const Member: React.FC<Props> = ({ member, memberIndex, onClick }) => {
  return (
    <>
      <style jsx>{`
        .wrapper {
          position: relative;
          cursor: pointer;
        }
        .wrapper:hover .container .member {
          opacity: 1;
        }
        .button {
          border: none;
          cursor: pointer;
          outline: none;
          display: block;
          text-align: left;
          width: 100%;
        }
        .container {
          position: relative;
          overflow: hidden;
          border-radius: 6px;
        }
        .avatar {
          display: flex;
          flex-direction: column;
        }
        .member {
          margin: 10px 0 0;
          display: flex;
          flex-direction: column;
        }
        .name {
          color: ${Color.defaultText};
          font-weight: 500;
          margin: 0;
        }
        .position {
          color: ${Color.defaultText};
          line-height: 1.25;
          margin: 0;
        }
        .position :global(.en) {
          font-size: 1.16em;
        }
        @media (${Variables.breakPoint.maxSp}) {
          .wrapper {
            width: calc(50% - 30px);
            margin: 15px;
          }
          .avatar :global(.avatarImage) {
            width: 100%;
            padding-top: 100%;
          }
          .name {
            font-size: 18px;
          }
          .position {
            font-size: 12px;
          }
        }
        @media (${Variables.breakPoint.minPc}) {
          .wrapper {
            margin-right: 30px;
            margin-bottom: 30px;
          }
          .avatar :global(.avatarImage) {
            width: 175px;
            height: 175px;
          }
          .member {
            max-width: 225px;
            width: 175px;
          }
          .name {
            font-size: 20px;
          }
          .position {
            font-size: 14px;
          }
        }
      `}</style>
      <li className="wrapper">
        <button type="button" className="button" onClick={() => onClick(memberIndex)}>
          <div className="container">
            <div className="avatar">
              <Img className="avatarImage" fixed={member?.avatar.fixed} />
            </div>
            <div className="member">
              <h2 className="name">{member.name}</h2>
              <p className="position">{renderTextNode(member.position, `${member.contentful_id}_list_position`)}</p>
            </div>
          </div>
        </button>
      </li>
    </>
  );
};
