import React from "react";
import { Color, Variables } from "../designToken";

type Props = {
  text: string;
  subText?: string;
};

export const SectionTitle: React.FC<Props> = ({ text, subText }) => {
  return (
    <>
      <style jsx>{`
        .titleWrapper {
          align-items: center;
          display: flex;
          justify-content: center;
          flex-direction: column;
          text-align: center;
        }
        .title {
          color: ${Color.brand};
          font-weight: 500;
          font-size: 54px;
          line-height: 1.25;
          border-bottom: 3px solid ${Color.brand};
          margin: 0;
          padding: 0 10px;
        }
        .subText {
          font-size: 15px;
          font-weight: 500;
          margin: 10px 0 0;
        }
        @media (${Variables.breakPoint.sp}) {
          .title {
            font-size: 42px;
          }
        }
      `}</style>
      <div className="titleWrapper">
        <h2 className="title">{text}</h2>
        {subText && <h3 className="subText">{subText}</h3>}
      </div>
    </>
  );
};
