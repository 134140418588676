import React, { useCallback, useState, useEffect } from "react";
import Img from "gatsby-image";
import Modal from "react-modal";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CloseIcon from "@material-ui/icons/Close";
import { Transition } from "react-transition-group";
import { renderTextNode } from "~/utils/renderTextNode";
import { Member } from "~/components/Member";
import { Variables, Color } from "~/components/designToken";
import { useMedia } from "~/utils/useMedia";
import TwitterSvg from "~/images/inline_svg/Twitter.inline.svg";
import FacebookSvg from "~/images/inline_svg/Facebook.inline.svg";
import NoteSvg from "~/images/inline_svg/Note.inline.svg";
import { ExternalLink } from "~/atoms/ExternalLink";

type Props = {
  members: GatsbyTypes.ContentfulMemberConnection;
};

const DURATION = 250;
const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

export const Members: React.FC<Props> = ({ members }) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(true);
  const [member, setMember] = useState<GatsbyTypes.ContentfulMember | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const media = useMedia();
  const isLargeSize = media && media.largeSize.matches;
  const handleOpenMemberModal = useCallback((memberIndex: number) => {
    setMember(members.edges[memberIndex].node);
    setCurrentIndex(memberIndex);
    setModalOpen(true);
  }, []);
  const handleShowNextMember = () => {
    setLoading(true);

    return setTimeout(() => {
      const memberIndex = currentIndex + 1;
      setLoading(false);
      setMember(members.edges[memberIndex].node);
      setCurrentIndex(memberIndex);
    }, DURATION);
  };
  const handleShowPreviousMember = () => {
    setLoading(true);

    return setTimeout(() => {
      const memberIndex = currentIndex - 1;
      setLoading(false);
      setMember(members.edges[memberIndex].node);
      setCurrentIndex(memberIndex);
    }, DURATION);
  };
  const nextAvailable = currentIndex !== members.edges.length - 1;

  useEffect(() => {
    Modal.setAppElement(window.document.body);
  }, []);

  return (
    <>
      <style jsx>{`
        .wrapper {
          display: flex;
          flex-wrap: wrap;
        }
        .button {
          border: none;
          cursor: pointer;
          outline: none;
          display: block;
        }
        .closeButton {
          padding: 0;
          text-align: left;
          position: absolute;
          right: 10px;
          top: 10px;
          z-index: 100;
          font-size: 24px;
          line-height: 1;
          width: 24px;
          height: 24px;
          color: ${Color.lightText};
        }
        .closeButton svg {
          display: block;
        }
        .modalWrapper {
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: 5px;
          overflow: hidden;
        }
        .memberWrapper {
          display: flex;
          justify-content: center;
        }
        .memberBody {
          padding: 15px 0 0;
          border-top: 1px solid ${Color.orange};
        }
        .memberName {
          font-weight: 500;
          color: ${Color.orange};
          line-height: 1;
        }
        .memberPosition {
          color: ${Color.defaultText};
          line-height: 1.25;
          margin: 10px 0 0;
        }
        .memberPosition :global(.en) {
          font-size: 1.16em;
        }
        .memberProfile {
          color: ${Color.defaultText};
          line-height: 1.75;
        }
        .memberProfile :global(.en) {
          font-size: 1.16em;
        }
        .memberSns {
          display: flex;
          align-items: center;
          margin: 20px 0 0;
        }
        .memberSns :global(a) {
          display: block;
        }
        .memberSns :global(a):not(:first-child) {
          margin: 0 0 0 10px;
        }
        .memberSnsIcon {
          display: block;
        }
        .pager {
          display: flex;
          align-items: center;
          margin: 20px 0 0;
        }
        .pagerPrev {
          color: ${Color.lightText};
          display: flex;
          align-items: center;
          margin: 0;
          font-size: 14px;
          line-height: 20px;
          transition: all 0.3s ease-in-out;
        }
        .pagerPrev:hover {
          color: ${Color.brand};
        }
        .pagerPrev svg {
          display: block;
        }
        .pagerPrev:hover svg {
          fill: ${Color.brand};
        }
        .pagerNext {
          color: ${Color.lightText};
          display: flex;
          align-items: center;
          margin: 0 0 0 auto;
          font-size: 14px;
          line-height: 20px;
          transition: all 0.3s ease-in-out;
        }
        .pagerNext:hover {
          color: ${Color.brand};
        }
        .pagerNext svg {
          display: block;
        }
        .pagerNext:hover svg {
          fill: ${Color.brand};
        }
        @media (${Variables.breakPoint.maxSp}) {
          .memberList {
            margin: 0 auto 40px;
            width: 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
          }
          .memberName {
            font-size: 28px;
          }
          .memberPosition {
            font-size: 13px;
          }
          .memberBody {
            margin: 15px 0 0;
          }
          .memberProfile {
            font-size: 13px;
          }
          .memberDetails {
            padding: 20px;
          }
          .memberSnsIcon {
            width: 24px;
            height: 24px;
          }
          .pager {
            margin: 20px 0 0;
          }
          .emptyListItem {
            width: calc(50% - 30px);
            margin: 15px;
          }
        }
        @media (${Variables.breakPoint.minPc}) {
          .container {
            max-width: 800px;
            margin: 0 auto;
            padding: 0;
          }
          .memberList {
            margin: 70px -30px 0 0;
            display: flex;
            flex-wrap: wrap;
          }
          .modalContainer {
            display: flex;
            align-items: stretch;
            overflow: hidden;
          }
          .memberAvatar {
            width: 50%;
            display: flex;
            flex-direction: column;
          }
          .memberAvatar :global(.gatsby-image-wrapper) {
            width: 100%;
            height: 100%;
            min-height: 400px;
          }
          .memberDetails {
            width: 50%;
            padding: 40px;
            display: flex;
            flex-direction: column;
          }
          .memberName {
            font-size: 32px;
          }
          .memberPosition {
            font-size: 14px;
          }
          .memberBody {
            margin: 15px 0 20px;
          }
          .memberProfile {
            font-size: 14px;
          }
          .memberSnsIcon {
            width: 28px;
            height: 28px;
          }
          .pager {
            margin: auto 0 0;
          }
          .emptyListItem {
            margin-right: 30px;
            margin-bottom: 30px;
            width: 175px;
          }
        }
      `}</style>
      <div className="wrapper">
        <ul className="memberList">
          {members.edges.map((edge, i) => (
            <Member
              key={`members_${edge.node.contentful_id}`}
              member={edge.node}
              memberIndex={i}
              onClick={handleOpenMemberModal}
            />
          ))}
          {members.edges.length % 4 > 0 && <li className="emptyListItem"></li>}
        </ul>
      </div>
      {member !== null && currentIndex !== null && isModalOpen && (
        <Modal
          isOpen
          onRequestClose={() => setModalOpen(false)}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 100,
              padding: "20px",
            },
            content: {
              overflow: "visible",
              width: "100%",
              border: "none",
              borderRadius: "5px",
              position: "static",
              padding: "0",
              minHeight: "300px",
              maxWidth: `${isLargeSize ? "800px" : "375px"}`,
            },
          }}
        >
          <div className="modalWrapper">
            <Transition in={!loading} mountOnEnter timeout={DURATION}>
              {(state) => (
                <div
                  className="modalContainer"
                  style={{
                    transition: `opacity ${DURATION}ms ease-in-out`,
                    opacity: 0,
                    ...transitionStyles[state],
                  }}
                >
                  <button className="button closeButton" onClick={() => setModalOpen(false)}>
                    <CloseIcon style={{ fontSize: 24 }} />
                  </button>
                  <div className="memberAvatar">
                    {isLargeSize ? <Img fixed={member?.avatar.fixed} /> : <Img fluid={member?.avatar.fluid} />}
                  </div>
                  <div className="memberDetails">
                    <h2 className="memberName">{member?.name}</h2>
                    <p className="memberPosition">
                      {renderTextNode(member?.position, `${member.contentful_id}_position`)}
                    </p>
                    <div className="memberBody">
                      <p className="memberProfile">
                        {renderTextNode(member?.profile.profile, `${member.contentful_id}_profile`)}
                      </p>
                      <div className="memberSns">
                        {member.twitter && (
                          <ExternalLink to={member?.twitter}>
                            <img src={TwitterSvg} className="memberSnsIcon" />
                          </ExternalLink>
                        )}
                        {member.facebook && (
                          <ExternalLink to={member?.facebook}>
                            <img src={FacebookSvg} className="memberSnsIcon" />
                          </ExternalLink>
                        )}
                        {member.note && (
                          <ExternalLink to={member?.note}>
                            <img src={NoteSvg} className="memberSnsIcon" />
                          </ExternalLink>
                        )}
                      </div>
                    </div>
                    <div className="pager">
                      {(currentIndex !== null && currentIndex > 0 && (
                        <button type="button" className="button pagerPrev" onClick={() => handleShowPreviousMember()}>
                          <ChevronLeftIcon style={{ fontSize: 20 }} />
                          Prev
                        </button>
                      )) ||
                        null}
                      {(currentIndex !== null && nextAvailable && (
                        <button type="button" className="button pagerNext" onClick={() => handleShowNextMember()}>
                          Next
                          <ChevronRightIcon style={{ fontSize: 20 }} />
                        </button>
                      )) ||
                        null}
                    </div>
                  </div>
                </div>
              )}
            </Transition>
          </div>
        </Modal>
      )}
    </>
  );
};
