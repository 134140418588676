import React from "react";
import parse from "html-react-parser";

export const renderTextNode = (str: string, key: string) => {
  const parseString = parse(str);
  if (typeof parseString === "string") return str;

  return parseString.map((s) => {
    if (typeof s === "object" && s.type === "span") {
      return (
        <span className="en" key={`${key}_${s.key}`}>
          {s.props.children}
        </span>
      );
    }
    return s;
  });
};
